import React from 'react'
import "../assets/css/Home.css"
import Contact from '../components/Contact'
import Skill from '../components/Skill'
import Example from '../components/Example'
import {motion} from 'framer-motion'
import avatar from '../assets/static/avatar.png'


export default function Home() {
  return (
  <>
  <div className="home-container">
      <div className='box-row'>
        <div className="box-text">
          <motion.p className="home-tittle" 
          initial={{opacity:0, x:'100vw'}}
          animate={{x:0, opacity:1}} 
          transition={{
            delay:0.5,
            duration:1}}
          dragMomentum={true}>UI Designer</motion.p>
        </div>
  </div>
    <div className='box-below'>
      <img src={avatar} alt="" />
    </div>
  </div>  
    {/* <Skill></Skill>
    <Example></Example>
    <Contact></Contact> */}
  </>
  )
}
